import { Notify, type QNotifyCreateOptions } from "quasar";

Notify.setDefaults({
  position: "top-right",
  classes: "notification",
  badgeClass: "notification--badge",
  timeout: 3000,
  html: true,
  actions: [
    {
      icon: "close",
      size: "small",
      color: "black",
      round: true,
      handler: () => {},
    },
  ],
});

Notify.registerType("system", {
  classes: "notification--system",
});

Notify.registerType("compare", {
  position: "bottom-right",
  timeout: 20000,
  group: 'compare',
  badgeStyle: { display: "none" },
  classes: "notification--compare",
});

Notify.registerType("error", {
  classes: "notification--error",
});

export class Notification {
  args: QNotifyCreateOptions | undefined;
  constructor(args: QNotifyCreateOptions | undefined) {
    this.args = args;
  }

  createNewToast(message?: string) {
    const newArgs: Record<string, any> = {};
    if (message || this.args?.message) {
      newArgs.message = message || this.args?.message;
    }
    return Notify.create({
      ...newArgs,
      ...this.args,
    });
  }
  createCompareToast(c: { has: number; all?: number; path: string }) {
    c.all = c.all ?? 4;
    const loc = window.location.origin;
    return Notify.create({
      message: `You have ${c.has} estates in comparison, ${c.has === c.all ? `that's maximum.` : `you can add ${c.all - c.has} more.`} <a
                href="${loc + c.path}"
                target="_blank"
                >Go compare</a>`,
      ...this.args,
    });
  }
}
